import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { videoTagString, VideoTag } from 'react-video-tag';
const Portfolio_image = <VideoTag autoPlay={`${true}`} muted={`${true}`} playsInline={`${true}`} loop={`${true}`} src={`${"/assets/images/service/1.mp4"}`} poster={`${"/assets/images/bg/bg-image-24.jpg"}`} />;
const Portfolio_image2 = <VideoTag autoPlay={`${true}`} muted={`${true}`} playsInline={`${true}`} loop={`${true}`} src={`${"/assets/images/service/2.mp4"}`} poster={`${"/assets/images/bg/bg-image-24.jpg"}`} />;
const Portfolio_image3 = <VideoTag autoPlay={`${true}`} muted={`${true}`} playsInline={`${true}`} loop={`${true}`} src={`${"/assets/images/service/3.mp4"}`} poster={`${"/assets/images/bg/bg-image-24.jpg"}`} />;
const Portfolio_image4 = <VideoTag autoPlay={`${true}`} muted={`${true}`} playsInline={`${true}`} loop={`${true}`} src={`${"/assets/images/service/4.mp4"}`} poster={`${"/assets/images/bg/bg-image-24.jpg"}`} />;
const Portfolio_image5 = <VideoTag autoPlay={`${true}`} muted={`${true}`} playsInline={`${true}`} loop={`${true}`} src={`${"/assets/images/service/5.mp4"}`} poster={`${"/assets/images/bg/bg-image-24.jpg"}`} />;
const Portfolio_image6 = <VideoTag autoPlay={`${true}`} muted={`${true}`} playsInline={`${true}`} loop={`${true}`} src={`${"/assets/images/service/6.mp4"}`} poster={`${"/assets/images/bg/bg-image-24.jpg"}`} />;

const PortfolioList = [
    {
        image: Portfolio_image,
        category: '',
        title: 'Cash Cow',
        description: '',
        link: "https://youtu.be/gSlHjks6ASg?si=42wJ3RynanVNXc7s"
    },
    {
        image: Portfolio_image2,
        category: '',
        title: 'Wildlife',
        description: '',
        link: "https://www.youtube.com/watch?v=VGvt3IWSbc8&ab_channel=Claws"
    },
    {
        image: Portfolio_image3,
        category: '',
        title: 'Story Based',
        description: '',
        link: "https://www.youtube.com/watch?v=O2Pt_H_7kCk&ab_channel=UNTAMED"
    },
    {
        image: Portfolio_image4,
        category: '',
        title: 'Explainer Videos',
        description: '',
        link: "https://www.youtube.com/watch?v=1kk96koviqU&ab_channel=Captivated"
    },
    {
        image: Portfolio_image5,
        category: '',
        title: 'Documentry',
        description: '',
        link: "https://youtu.be/J8Flo1VxxsA?si=VCG850cLlCHhBmTA"
    },
    {
        image: Portfolio_image6,
        category: '',
        title: 'Space Travel',
        description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.',
        link: "https://youtu.be/zU8Z-R6zlto?si=Az3-L3jyulzBpr94"
    }

]

class Portfolio extends Component {
    render() {
        let title = 'Some of our Recent Works',
            description = 'Discover Our Spectacular New YouTube Creations!';
        return (
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">Our project</span>
                                    <h2 className="title">{title}</h2>
                                    <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--30">
                            {PortfolioList.map((value, index) => (
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={index}>
                                    <div className="im_portfolio">
                                        <div className="thumbnail_inner">
                                            <div className="thumbnail">
                                                <a href={value.link} target="_blank">
                                                    {value.image}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <div className="portfolio_heading">
                                                    <div className="category_list">
                                                        <a href={value.link} target="_blank">{value.category}</a>
                                                    </div>
                                                    <h4 className="title"><a href={value.link} target="_blank">{value.title}</a></h4>
                                                </div>
                                                <div className="portfolio_hover">
                                                    <p>{value.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <a href={value.link} target="_blank" className="transparent_link" ></a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;