import React, { Component } from "react";
import { FiActivity, FiCast, FiMap } from "react-icons/fi";


const ServiceList = [
    {
        icon: <img style={{maxWidth: "15%"}} src="/assets/images/homeIcons/script.png" alt="" />,
        title: 'Script Writing',
        description: 'Where Every Single Word Sparks Magic!'
    },
    {
        icon: <img style={{maxWidth: "15%"}}  src="/assets/images/homeIcons/voice-record.png" alt="" />,
        title: 'Voice Over ',
        description: "Voiceover Charm to bring Stories to Life" 
    },
    {
        icon: <img style={{maxWidth: "15%"}}  src="/assets/images/homeIcons/video-editing.png" alt="" />,
        title: 'Video Editing',
        description: 'Elevate Your Content with Expert Editing'
    },
    {
        icon: <img style={{maxWidth: "15%"}}  src="/assets/images/homeIcons/grading.png" alt="" />,
        title: 'Color Grading',
        description: 'Transform your visuals with stunning color grading.'
    },
    {
        icon: <img style={{maxWidth: "15%"}} src="/assets/images/homeIcons/thumbnail.png" alt="" />,
        title: 'Thumbnail Designs',
        description: 'Thumbnail Magic, Boost Clicks, Engage Audiences!'
    },
    {
        icon: <img style={{maxWidth: "15%"}}  src="/assets/images/homeIcons/video-optimization.png" alt="" />,
        title: 'Video Optimization',
        description: 'Platform Specific Edits for Maximum Reach!'
    },
   
    
]
class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row row--25">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    {val.icon}
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;