import React, { Component } from "react";

class BrandTwo extends Component {
    render() {
        return (
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <a href="https://www.youtube.com/@TheFinestX" target="_blank">
                            <img src="/assets/images/brand/The_Finest.png" alt="The Finest" />
                        </a>
                    </li>

                    <li>
                        <a href="https://www.youtube.com/@theamazingbanana1" target="_blank">

                            <img src="/assets/images/brand/Amazing_Banana.png" alt="Amazing Banana" />
                        </a>
                    </li>

                    <li>
                        <a href="https://www.youtube.com/@beyonddiscoveryofficial" target="_blank">

                            <img src="/assets/images/brand/Beyond_Discovery.png" alt="Beyond Discovery" />
                        </a>
                    </li>


                    <li>
                        <a href="https://www.youtube.com/@sleepiscanceled/videos" target="_blank">
                            <img src="/assets/images/brand/Sleep_Is_Cancelled.png" alt="Sleep Is Cancelled" />
                        </a>
                    </li>

                    <li>
                        <a href="https://www.youtube.com/@ReelCentral" target="_blank">
                            <img src="/assets/images/brand/Reel_central.png" alt="Reel central" />
                        </a>
                    </li>

                    <li>
                        <a href="https://www.youtube.com/@moviesexplainedvideos" target="_blank">
                            <img src="/assets/images/brand/movie_explained.png" alt="Movie Explained" />
                        </a>
                    </li>

                    <li>
                        <a href="https://www.youtube.com/@futurize456" target="_blank">
                            <img src="/assets/images/brand/futurize.png" alt="futurize" />
                        </a>
                    </li>

                    <li>
                        <a href="https://www.youtube.com/@TopDiscovery" target="_blank">
                            <img height={200} src="/assets/images/brand/Top_Discovery.png" alt="Top Discovery" />
                        </a>
                    </li>

                    <li>
                        <a href="https://www.youtube.com/@TopMysteryVideos" target="_blank">
                            <img src="/assets/images/brand/Top_Mystery.png" alt="Top Mystery" />
                        </a>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;